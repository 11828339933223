import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby-plugin-intl'
import Layout from '../components/Layout'
import PostBlock from '../components/Pages/Blog/PostBlock'
import BlogMenu from '../components/Pages/Blog/BlogMenu'



import './blog.scss'

export default props => {
  const { data } = props
  const posts = data.allMarkdownRemark.edges
  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = `/blog/${currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()}`
  const nextPage = `/blog/${(currentPage + 1).toString()}`
  
  return (
    <Layout className="blogPage blogPosts">
      <div className="container row">
        <div className="blogPosts__header">
          <div className="blogPosts__headline">
            <h1><Link to="/blog">Blog</Link></h1>
          </div>
          <BlogMenu />
        </div>

        <div className="blogPosts__posts">
          {posts.map(({ node }, i) =>
            <PostBlock key={i} post={node} />
          )}
        </div>
        {Array.from({ length: numPages }, (_, i) => (
          i > 1 &&
          <div className="blogPage__pagination">
            {!isFirst && (
              <Link to={prevPage} rel="prev" className="pagination__prev">
                <span>Previous Page</span>
              </Link>
            )}
            
              <Link key={`pagination-number${i + 1}`}
                to={`/blog/${i === 0 ? '' : i + 1}`}

              >
                {i + 1}
              </Link>
            
            {!isLast && (
              <Link to={nextPage} rel="next" className="pagination__next">
                <span>Next Page</span>
              </Link>
            )}
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!, $category: String) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { 
        frontmatter: { 
          category: { 
            eq: $category
          },
          pageKey: { 
            eq: "page_blogpost"
          }
        } 
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            locale
            
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`